
import { defineComponent,ref, onMounted } from "vue";
import KTWidget6 from "@/components/widgets/feeds/Widget6.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {ElLoading} from "element-plus";
import {useRoute, useRouter} from "vue-router";
import {Actions} from "@/store/enums/StoreEnums";
import {useI18n} from "vue-i18n";
import EventCard from "@/components/events/EventCard.vue";
export default defineComponent({
  name: "wishlist",
  components: {
    EventCard
  },

data() {
  const route = useRoute()
  const router = useRouter()
  const { t } = useI18n();
  const isUserAuthenticated = store.getters.isUserAuthenticated
  if(isUserAuthenticated == false) {
    router.push({ name: "dashboard" });
  }
  const loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(255, 255, 255, 0.7)',
  })

  return {
    loading,
    wishlist:{}
  }
},
  methods: {
    userWishlist() {
       store.dispatch(Actions.GET_USER_WISHLIST).then(response => {
        this.loading.close();
        this.wishlist=response.data;
      }, error => {
        console.error("Got nothing from server. Please check internet connection and try again")
      });
    }
  },
 async created() {
    await this.userWishlist();
  },

});
