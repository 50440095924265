import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-body pt-0" }
const _hoisted_5 = {
  key: 0,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventCard = _resolveComponent("EventCard")!
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('my_wishlist')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (Object.keys(_ctx.wishlist).length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wishlist, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-12 col-lg-3 event-item",
                key: index
              }, [
                _createVNode(_component_EventCard, {
                  data: item,
                  index: index
                }, null, 8, ["data", "index"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (Object.keys(_ctx.wishlist).length === 0)
        ? (_openBlock(), _createBlock(_component_el_empty, {
            key: 1,
            description: _ctx.$t('NOTHING_FOUND')
          }, null, 8, ["description"]))
        : _createCommentVNode("", true)
    ])
  ]))
}