import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "event_card_container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "event_card_info" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "price-start-from" }
const _hoisted_6 = { class: "place" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: `/event/${_ctx.data.id}`
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          class: "img-fluid main_img",
          src: _ctx.site_url +'/'+ _ctx.data?.main_image
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.data?.name), 1),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('price_start_from')) + ": " + _toDisplayString(_ctx.data?.price), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.data?.place), 1)
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}