
import { defineComponent } from "vue";

export default defineComponent({
  name: "event-card",
  props: {
    data: Object,
    index: Number,
  },
  data() {
    return {
      site_url: process.env.VUE_APP_BACKEND_URL
    }
  },
  components: {},

});
